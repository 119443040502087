import React from 'react';

const NewYearBanner = () => {
  return (
    <div className="relative w-full py-12 mb-8 overflow-hidden bg-gradient-to-br from-purple-900 to-blue-900">
      {/* Arka plan efekti */}
      <div className="absolute inset-0 bg-black/10" />
      <div className="absolute inset-0">
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id="dots" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <circle cx="10" cy="10" r="1" fill="rgba(255, 255, 255, 0.1)" />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#dots)" />
        </svg>
      </div>

      {/* Ana içerik */}
      <div className="relative mx-auto max-w-4xl px-4">
        <div className="relative text-center">
          {/* Dekoratif çizgiler */}
          <div className="absolute top-0 left-0 w-full">
            <svg viewBox="0 0 100 10" className="w-full h-8">
              <path
                d="M0,5 L100,5"
                fill="none"
                stroke="rgba(147, 51, 234, 0.3)"
                strokeWidth="0.5"
                strokeDasharray="4 2"
              />
            </svg>
          </div>

          {/* Ana başlık */}
          <h1 className="text-5xl md:text-6xl font-bold tracking-tight mb-4">
            <div className="relative inline-block transform transition-transform hover:scale-105">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-200 animate-float-1">
                #2025
              </span>
            </div>
            <div className="relative inline-block transform transition-transform hover:scale-105">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-200 animate-float-2">
                Yolunda
              </span>
            </div>
          </h1>

          {/* Alt başlık */}
          <p className="text-lg md:text-xl text-gray-200 mt-4 max-w-2xl mx-auto">
            "Her adım bizi hedeflerimize biraz daha yaklaştırır"
          </p>

          {/* İkon ve dekoratif elementler */}
          <div className="mt-6 flex justify-center space-x-8">
            <span className="text-2xl animate-bounce-slow">🎯</span>
            <span className="text-2xl animate-pulse">⭐</span>
            <span className="text-2xl animate-bounce-slow">🚀</span>
          </div>
        </div>
      </div>

      <style>{`
        @keyframes float-1 {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-10px); }
        }

        @keyframes float-2 {
          0%, 100% { transform: translateY(-10px); }
          50% { transform: translateY(0px); }
        }

        .animate-float-1 {
          animation: float-1 3s ease-in-out infinite;
        }

        .animate-float-2 {
          animation: float-2 3s ease-in-out infinite;
          animation-delay: 0.2s;
        }

        .animate-bounce-slow {
          animation: bounce 2s infinite;
        }
      `}</style>
    </div>
  );
};
export default NewYearBanner;
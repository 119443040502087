import React from 'react';

const HolidayDecorations = () => {
  return (
    <>
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {/* Üst kısım ışık dizisi */}
        <div className="flex justify-around w-full">
          {Array.from({ length: 40 }).map((_, i) => (
            <div key={i} className="relative">
              <div
                className={`w-2 h-2 rounded-full ${i % 2 === 0 ? 'animate-twinkle-1' : 'animate-twinkle-2'}`}
                style={{
                  backgroundColor: i % 3 === 0 ? '#FFD700' : i % 3 === 1 ? '#D42C2A' : '#1F6357',
                  boxShadow: `0 0 8px ${i % 3 === 0 ? '#FFD700' : i % 3 === 1 ? '#D42C2A' : '#1F6357'}`
                }}
              />
            </div>
          ))}
        </div>

        {/* Sol üst süsleme */}
        <div className="absolute -top-2 -left-2 w-40 h-40">
          <svg viewBox="0 0 100 100" className="w-full h-full opacity-80">
            <path
              d="M0,50 Q25,25 50,50 T100,50"
              fill="none"
              stroke="#D42C2A"
              strokeWidth="2"
              className="animate-dash"
            />
            <path
              d="M0,60 Q25,35 50,60 T100,60"
              fill="none"
              stroke="#1F6357"
              strokeWidth="2"
              className="animate-dash-reverse"
            />
          </svg>
        </div>

        {/* Sağ üst süsleme */}
        <div className="absolute -top-2 -right-2 w-40 h-40 transform scale-x-[-1]">
          <svg viewBox="0 0 100 100" className="w-full h-full opacity-80">
            <path
              d="M0,50 Q25,25 50,50 T100,50"
              fill="none"
              stroke="#D42C2A"
              strokeWidth="2"
              className="animate-dash"
            />
            <path
              d="M0,60 Q25,35 50,60 T100,60"
              fill="none"
              stroke="#1F6357"
              strokeWidth="2"
              className="animate-dash-reverse"
            />
          </svg>
        </div>

        {/* Dekoratif alt çizgi */}
        <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-transparent via-red-600/20 to-transparent" />
      </div>

      <style>{`
        @keyframes twinkle-1 {
          0%, 100% { opacity: 1; transform: scale(1); }
          50% { opacity: 0.4; transform: scale(0.8); }
        }

        @keyframes twinkle-2 {
          0%, 100% { opacity: 0.4; transform: scale(0.8); }
          50% { opacity: 1; transform: scale(1); }
        }

        @keyframes dash {
          to {
            stroke-dashoffset: -100;
          }
        }

        @keyframes dash-reverse {
          to {
            stroke-dashoffset: 100;
          }
        }

        .animate-twinkle-1 {
          animation: twinkle-1 2s ease-in-out infinite;
        }

        .animate-twinkle-2 {
          animation: twinkle-2 2s ease-in-out infinite;
        }

        .animate-dash {
          stroke-dasharray: 20;
          animation: dash 20s linear infinite;
        }

        .animate-dash-reverse {
          stroke-dasharray: 20;
          animation: dash-reverse 20s linear infinite;
        }
      `}</style>
    </>
  );
};

export default HolidayDecorations;
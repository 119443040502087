import React, { useEffect, useState } from 'react';

const SnowEffect = () => {
  const [snowflakes, setSnowflakes] = useState([]);
  
  useEffect(() => {
    const createSnowflake = () => ({
      id: Math.random(),
      left: Math.random() * 100,
      animationDuration: 5 + Math.random() * 10,
      opacity: 0.4 + Math.random() * 0.6,
      size: 4 + Math.random() * 6
    });
    
    // Initial snowflakes
    setSnowflakes(Array.from({ length: 100 }, createSnowflake));
    
    // Add new snowflakes periodically
    const interval = setInterval(() => {
      setSnowflakes(prev => [...prev.slice(-49), createSnowflake()]);
    }, 200);
    
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="fixed inset-0 pointer-events-none z-50">
      {snowflakes.map((snowflake) => (
        <div
          key={snowflake.id}
          className="absolute animate-fall bg-white rounded-full"
          style={{
            left: `${snowflake.left}%`,
            top: '-10px',
            width: `${snowflake.size}px`,
            height: `${snowflake.size}px`,
            opacity: snowflake.opacity,
            animation: `fall ${snowflake.animationDuration}s linear infinite`
          }}
        />
      ))}
      
      <style>{`
        @keyframes fall {
          0% {
            transform: translateY(-10px) rotate(0deg);
          }
          100% {
            transform: translateY(100vh) rotate(360deg);
          }
        }
        .animate-fall {
          animation: fall linear infinite;
        }
      `}</style>
    </div>
  );
};

export default SnowEffect;